@font-face {
  font-family: 'Bree';
  src: url('./fonts/BreeRegular.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'BreeBold';
  src: url('./fonts/BreeBold.otf') format('opentype');
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: 'BreeThin';
  src: url('./fonts/BreeThin.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'BreeObliqueThin';
  src: url('./fonts/BreeOblique_Thin.otf') format('opentype');
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: 'BreeLight';
  src: url('./fonts/BreeLight.otf') format('opentype');
  font-style: normal;
  font-weight: lighter;
}
@font-face {
  font-family: 'BreeObliqueBold';
  src: url('./fonts/BreeOblique_Bold.otf') format('opentype');
  font-style: oblique;
  font-weight: bold;
}
@font-face {
  font-family: 'BreeObliqueLight';
  src: url('./fonts/BreeOblique_Light.otf') format('opentype');
  font-style: oblique;
  font-weight: lighter;
}
@font-face {
  font-family: 'BreeOblique';
  src: url('./fonts/BreeOblique_Reg.otf') format('opentype');
  font-style: oblique;
  font-weight: normal;
}

body {
  margin: auto;
  padding: 0;
  height: 100vh;
  background-size: cover;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root{
  height: 100%;
  min-height: 100%;
}

footer{
  width: 100%;
  /* background: rgba(0, 0, 0, 0.712); */
  color: #ffffff;
}
*{
  font-family: 'Bree';
}
.BreeThin{font-family:'BreeThin'}
.BreeLight{font-family:'BreeLight'}
.BreeBold{font-family:'BreeBold'}
.BreeObliqueThin{font-family:'BreeObliqueThin'}
.BreeObliqueLight{font-family:'BreeObliqueLight'}
.BreeOblique{font-family:'BreeOblique'}
.BreeObliqueBold{font-family:'BreeObliqueBold'}

.bg-main{
  background-color: rgb(143,212,0);
  color: #ffffff;
}
.bg-gray{
  background-color: rgb(88,88,88);
  color: #ffffff;
}
.bg-white{
  background-color: #ffffff;
  color: rgb(88,88,88);
}

.text-main{
  color: rgb(143,212,0);
}
.text-gray{
  color: rgb(88,88,88);
}

.nav-link, .nav-link:hover{
  color:rgb(88,88,88);
}
.nav-tabs .nav-link.active{
  color:rgb(143,212,0);
}